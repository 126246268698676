export const createUser = async (user) => {
    user.time = new Date();
    sessionStorage.setItem('user', JSON.stringify(user));
};

export const getUser = async (redirect) => {
    let user = JSON.parse(sessionStorage.getItem('user')),
        currentDate = new Date();

    if (!(new Date(user.time) > currentDate.setHours(currentDate.getHours() - 3))) {
        await deleteUser();
        if (redirect) {
            window.location.href = '/login';
        }
    }

    return JSON.parse(sessionStorage.getItem('user'));
};

export const deleteUser = async () => {
    sessionStorage.removeItem('user');
};
